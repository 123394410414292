<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M13 18.945V20h1a2 2 0 012 2H8a2 2 0 012-2h1v-1.055a8.972 8.972 0 01-5.206-2.427l-1.055 1.156a1 1 0 01-1.478-1.348l1.788-1.959a1 1 0 011.515.044 7 7 0 109.557-10.07 1 1 0 111.18-1.615 9.001 9.001 0 01-4.3 16.22z" fill="#000" fill-rule="nonzero"/><circle fill="#000" opacity=".3" cx="12" cy="10" r="6"/></g></svg>
          Domain Products
        </h3>
      </template>
      <template v-slot:body>
        <div v-if="loading.banner">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-carousel
            v-if="success.banner"
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            img-width="1024"
            img-height="300"
          >
            <b-carousel-slide
              v-for="(banner, index) in xhr.banner"
              :key="index"
              :caption="banner.caption"
              :img-src="banner.image"
            ></b-carousel-slide>
          </b-carousel>

          <b-alert variant="danger" show v-else>{{
            xhr.banner.message
          }}</b-alert>
        </div>
      </template>
    </RWCard>
    <b-card>
      <template v-slot:header>
        <b-form inline @submit.stop.prevent="search">
          <label class="sr-only">Domain Name</label>
          <b-input
            id="inline-form-input-name"
            class="my-2"
            placeholder="eq. mydomain.id"
            v-model="form_search.domain"
            autocomplete="off"
          ></b-input>

          <b-row>
            <b-col cols="8">
              <b-form-select
                class="my-2 ml-sm-4"
                required
                :options="status"
                v-model="form_search.status"
                @change="search()"
              ></b-form-select>
            </b-col>
            <b-col cols="4">
              <b-button type="submit" class="m-2" variant="primary"
                >Find</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </template>

      <!-- table -->
      <div v-if="loading.product">
        <b-skeleton-table
          :rows="pagination.limit"
          :columns="5"
          :table-props="{ striped: true }"
        ></b-skeleton-table>
      </div>
      <div v-else>
        <div v-if="success.product">
          <ShowProduct
            :loading="loading.pagination"
            :data="xhr.product"
            type="domain"
          ></ShowProduct>

          <!-- pagination -->
          <b-pagination
            v-model="pagination.current"
            :total-rows="pagination.allCount"
            :per-page="pagination.limit"
            @change="handlePagination"
            align="right"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </div>
        <div v-else>
          <b-alert variant="danger" show>{{ xhr.product.message }}</b-alert>
        </div>
      </div>
      <!-- endtable -->
    </b-card>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import ShowProduct from "@/views/pages/product/slot/slotProductShow";
import { breadcrumb, ApiURL, util } from "@/common/mixins/general.js";

export default {
  name: "ProductDomain",
  mixins: [breadcrumb, ApiURL, util],
  components: {
    RWCard,
    ShowProduct
  },
  data() {
    return {
      loading: {
        banner: true,
        product: true,
        pagination: false
      },
      success: {
        banner: false,
        product: false
      },

      /**
       * -----------------------------------------
       * data dari request API
       * -----------------------------------------
       */
      xhr: {
        banner: null,
        product: []
      },

      form_search: {
        domain: "",
        status: "All"
      },
      status: [
        "All",
        "Active",
        "Pending",
        "Cancelled",
        "Pending Registration",
        "Pending Transfer",
        "Grace",
        "Redemption",
        "Expired",
        "Fraud",
        "Transferred Away"
      ],
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * generate /submit pencarian (domain, status)
     * -----------------------------------------
     */
    search() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.loading.product = true;
      this.get_service();
    },

    /**
     * -----------------------------------------
     * get banner iklan domain
     * -----------------------------------------
     */
    get_banner() {
      this.xhr_domain("banner")
        .then(({ data }) => {
          this.xhr.banner = data.data.banner;
          this.success.banner = true;
        })
        .catch(({ response }) => {
          this.xhr.banner = response.data;
        })
        .finally(() => {
          this.loading.banner = false;
        });
    },

    /**
     * -----------------------------------------
     * get table product /service hosting
     * -----------------------------------------
     */
    get_service() {
      let query = this.mx_ObjectToQuery(this.query_request, "?");
      this.xhr_domain("index", query)
        .then(({ data }) => {
          this.xhr.product = data.data.service.record;
          this.pagination.allCount = data.data.service.count;
          this.success.product = true;
        })
        .catch(({ response }) => {
          // handle jika ketika next page ada error
          this.success.product = false;
          this.xhr.product = response.data;
          let current = this.pagination.current;
          this.pagination.current = current !== 1 ? current - 1 : 1;
        })
        .finally(() => {
          this.loading.product = false;
          this.loading.pagination = false;
        });
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(page) {
      this.loading.pagination = true;
      this.pagination.offset = (page - 1) * this.pagination.limit;

      this.get_service();
    }
  },
  computed: {
    /**
     * -------------------------------------------------
     * generate query request data
     * -------------------------------------------------
     */
    query_request: function() {
      let query = {};
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;

      if (this.form_search.status !== "All") {
        query.status = this.form_search.status;
      }
      if (this.form_search.domain !== "") {
        query.search = this.form_search.domain;
      }
      return query;
    }
  },
  mounted() {
    this.get_banner();
    this.get_service();
    this.mx_DomainBreadcrumb();
  }
};
</script>
